export default {
  formTitle: "Schneller & besser finanzieren mit dem Eilkredit.",
  formSubTitle: "Auszahlung in 5 Min.",
  heading: "Eilkredit mit Sofortauszahlung",
  subHeading: "Zeit sparen dank Online-Verfahren.",
  notice: `Brauchen Sie möglichst schnell Zugang zu einem Kredit? Wir können Ihnen den gewünschten Betrag über den Eilkredit schon in unter 24 Stunden vermitteln. Dieser Service wird durch unsere schnelle und unkomplizierte Anfragenbearbeitung ermöglicht.

  Sofort nach Antragseingang erstellen unsere Experten Ihr persönliches Angebot - ganz nach Ihren Wünschen.`,
  itemsTitle: "Der Eilkredit bietet viele Vorteile:",
  items: [
    " 100% kostenlose und unverbindliche Antragstellung (Wie es der Rechtslage entspricht)",
    "Unbürokratisch, schnell und auf Sie abgestimmt",
    "Keine Zweckbindung - machen Sie mit Ihrem Geld was Sie wollen!",
    "Unsere Experten beraten Sie jederzeit kompetent und professionell",
    "Bank- oder Beratertermine entfallen für Sie",
    "Garantierte sofortige Antragsannahme",
    "Wir passen Laufzeiten an Ihre persönliche Situation an",
    "Abwicklung auf dem Postweg - sparen Sie sich lästige Gänge zu Beratern oder Banken",
    "Zusage erfolgt sofort online!",
    "Auszahlung Ihres Wunschbetrages erfolgt innerhalb von max. 24 Stunden",
  ],
  tncText: "*kostenlos & unverbindlich (Wie es der Rechtslage entspricht)",
  btnText: "Jetzt Antrag erstellen*",
  repsTitle: "REPRÄSENTATIVEN BEISPIEL",
  repsExample: `*Angaben gem. § 6a PAngV: <br/><br/>Unveränderlicher Sollzinssatz ab 3,92%, effektiver Jahreszins ab 3,99% - 15,99%, Nettodarlehensbetrag ab 1.000,- bis 50.000,- €, Laufzeit von 12 bis 120 Monaten, Bonität vorausgesetzt. Bei einem Nettodarlehensbetrag von 10.000,- € und einer Laufzeit von 36 Monaten erhalten 2/3 der Kunden von Sofort24Kredit vorraussichttlich einen eff. Zinssatz von 8,90% oder günstiger (geb. Sollzinssatz 5,26%)."`,
}
